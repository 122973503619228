* {
  box-sizing: border-box;
}

body,
html {
  font-family: 'AvenirLTStd-Light';
}

@font-face {
  font-family: 'AvenirLTStd-Light';
  src: local('Avenir LT Std 35 Light'), local('AvenirLTStd-Light'),
    url('AvenirLTStd-Light.woff2') format('woff2'),
    url('AvenirLTStd-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirLTStd-Medium';
  src: local('AvenirLTStd-Medium'),
    url('AvenirLTStd-Medium.woff2') format('woff2'),
    url('AvenirLTStd-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirLTPro-Black';
  src: local('Avenir LT Pro 95 Black'), local('AvenirLTPro-Black'),
    url('AvenirLTPro-Black.woff2') format('woff2'),
    url('AvenirLTPro-Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirLTPro-Book';
  src: local('Avenir LT Pro 45 Book'), local('AvenirLTPro-Book'),
    url('AvenirLTPro-Book.woff2') format('woff2'),
    url('AvenirLTPro-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirLTPro-Roman';
  src: local('Avenir LT Pro 55 Roman'), local('AvenirLTPro-Roman'),
    url('AvenirLTPro-Roman.woff2') format('woff2'),
    url('AvenirLTPro-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirLTPro-Medium';
  src: local('Avenir LT Pro 65 Medium'), local('AvenirLTPro-Medium'),
    url('AvenirLTPro-Medium.woff2') format('woff2'),
    url('AvenirLTPro-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirLTPro-Light';
  src: local('Avenir LT Pro 35 Light'), local('AvenirLTPro-Light'),
    url('AvenirLTPro-Light.woff2') format('woff2'),
    url('AvenirLTPro-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirLTPro-Heavy';
  src: local('Avenir LT Pro 85 Heavy'), local('AvenirLTPro-Heavy'),
    url('AvenirLTPro-Heavy.woff2') format('woff2'),
    url('AvenirLTPro-Heavy.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
